"use client";
import { DIGITS } from "@/utils/constants";

export default function LoginMobilePassword({
    content = {},
    locale = "en",
    errorMsg = "",
    setLoginDetails = function () {},
    setErrorMsg = function () {},
    setShowPassword = function () {},
    showPassword = false,
    password = "",
}) {
    return (
        <div
            className={
                "form-group" +
                (errorMsg?.length > DIGITS?.ZERO &&
                content?.passwordRequired &&
                !password
                    ? " error"
                    : "")
            }
        >
            <label className="ffv !text-brown" htmlFor="passwd_phone">
                {content?.passwordLabel || "Password"}{" "}
                {content?.passwordRequired ? <sup>*</sup> : ""}
            </label>
            <input
                className={
                    "track password " + showPassword
                        ? "password-visible digits-iq"
                        : ""
                }
                type={showPassword ? "text" : "password"}
                name="passwd_phone"
                placeholder={content?.passwordPlaceholder || ""}
                value={password}
                onChange={(e) => {
                    if (errorMsg?.length > DIGITS?.ZERO) setErrorMsg("");
                    setLoginDetails((state) => ({
                        ...state,
                        password: e?.target?.value || "",
                    }));
                }}
                autoComplete="new-password"
            />
            <div className="ffv error-text">
                <p>
                    {errorMsg?.length > DIGITS?.ZERO
                        ? content?.mobileLoginError || ""
                        : ""}
                </p>
            </div>
            <div
                className={
                    "visibility-button position-absolute " +
                    (["ar", "kd"].includes(locale) ? "right-eye" : "left-eye")
                }
                onClick={() => {
                    setShowPassword((state) => !state);
                }}
            >
                {showPassword ? (
                    <i className="far fa-eye"></i>
                ) : (
                    <i className="far fa-eye-slash"></i>
                )}
            </div>
        </div>
    );
}
