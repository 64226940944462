"use client";
import PhonePrefixSelect from "@/components/common/phone-prefix-select";
import { DIGITS } from "@/utils/constants";
import { hasLetter } from "@/utils/functions.client";

export default function LoginMobilePhoneNumber({
    content = {},
    locale = "en",
    errorMsg = "",
    setLoginDetails = function () {},
    setErrorMsg = function () {},
    phonePrefixes = [],
    login = "",
    phonePrefix = "",
}) {
    return (
        <div
            className={
                "form-group" +
                (errorMsg?.length > DIGITS?.ZERO &&
                content?.mobileRequired &&
                !login
                    ? " error"
                    : "")
            }
            id="form_register_phone"
        >
            <label className="ffv !text-brown" htmlFor="mobile-number">
                {content?.mobileLabel || "Mobile Number"}
                {content?.mobileRequired ? <sup>*</sup> : ""}
            </label>
            <div
                className={`${
                    locale !== "en" ? "flex flex-row-reverse" : ""
                } input-row`}
            >
                <div
                    className={`flag-picker ${
                        locale !== "en" ? "!ml-0 !mr-3" : ""
                    }`}
                >
                    <PhonePrefixSelect
                        phonePrefixes={phonePrefixes || []}
                        countryPrefix={
                            phonePrefix || DIGITS?.DIGIT_964?.toString()
                        }
                        setPrefixCode={(value) => {
                            if (errorMsg?.length > DIGITS?.ZERO)
                                setErrorMsg("");
                            setLoginDetails((state) => ({
                                ...state,
                                phonePrefix: value,
                            }));
                        }}
                        lang={locale}
                    />
                </div>
                <input
                    className="flag-picker-input digits-iq"
                    type="text"
                    name="phone"
                    pattern="\d*"
                    maxLength="10"
                    placeholder={content?.mobilePlaceholder || ""}
                    value={login}
                    onChange={(e) => {
                        if (errorMsg?.length > DIGITS?.ZERO) setErrorMsg("");
                        let value = e?.target?.value?.trim() || "";
                        value =
                            value.startsWith(DIGITS?.ZERO?.toString()) &&
                            phonePrefix !== DIGITS?.DIGIT_225?.toString()
                                ? value.slice(DIGITS?.ONE)
                                : value;
                        if (!hasLetter(value)) {
                            setLoginDetails((state) => ({
                                ...state,
                                login: value,
                            }));
                        }
                    }}
                />
            </div>
        </div>
    );
}
