"use client";
import Link from "next/link";

export default function LoginError({ content = {}, errorMsg = "" }) {
    return errorMsg ? (
        <div className="login-alert alert d-block text-justify border-orange">
            <div className="alert__text">
                <p className="ffv reqular-iq">
                    {content?.loginErrorMessage || ""}
                </p>
                <div className="ffv alert__text--button">
                    <Link
                        className="btn btn--primary bold-iq"
                        href={content?.signUp?.link || "#sign-up"}
                    >
                        {content?.signUp?.text || ""}
                    </Link>
                </div>
            </div>
        </div>
    ) : (
        ""
    );
}
